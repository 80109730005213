<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <div class="d-flex justify-space-between flex-wrap pa-4">
          <v-dialog v-model="dialog" tabindex="-1" persistent max-width="600px">
              <template v-slot:activator="{on, attrs}">
                
                  <v-btn
                      class="rounded-lg py-5"
                      tile
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                  >
                      <v-icon left class="pr-2">
                          mdi-plus
                      </v-icon>
                      Add Product
                  </v-btn>
              </template>
              <v-card class="">
                  <v-card-title>
                      <span class="headline">Add Product</span>
                  </v-card-title>
                  <v-card-text>
                      <v-form ref="productForm" v-model="validProductForm" validation>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field
                                        v-model="productFormData.name"
                                        label="Name"
                                        required
                                        dense
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="productFormData.price"
                                        label="Price"
                                        required
                                        dense
                                    ></v-text-field>
                                </v-col>
                              <v-col cols="6">
                                <v-select 
                                    :rules="[(v) => !!v || 'Item is required']"
                                    required
                                    v-model="productFormData.currencyId"
                                    dense
                                    :items="getCurrencyList"
                                    item-text="isoCode"
                                    item-value="currencyId"
                                    label="Currency" />
                                </v-col>
                              <v-col cols="12">
                                <v-select 
                                    :rules="[(v) => !!v || 'Item is required']"
                                    required
                                    v-model="productFormData.taxRates"
                                    dense
                                    multiple
                                    chips
                                    :items="taxRates"
                                    item-text="name"
                                    item-value="taxId"
                                    label="Tax Rates" />
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field
                                        v-model="productFormData.description"
                                        label="Description"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                      </v-form>
                      <small>*indicates required field</small>
                  </v-card-text>
                  <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="primary darken-1"
                          text
                          @click="dialog = false"
                          >Close</v-btn
                      >
                      <v-btn
                          color="primary darken-1"
                          text
                          :disabled="!validProductForm"
                          @click="submitProduct"
                          >Save</v-btn
                      >
                  </v-card-actions>
              </v-card>
          </v-dialog>
          <!-- <div>
              <v-btn class="ma-2" color="primary">
                  <v-icon>mdi-cog</v-icon>
              </v-btn>
              <v-btn outlined class="ma-2">Import</v-btn>
              <v-btn outlined class="ma-2">Export</v-btn>
          </div> -->
        </div>
        <v-card-title>
          Products
          <v-spacer />
          <v-spacer />
          <v-spacer />
          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          v-model="selected"
          :search="search"
          :headers="headers"
          :items="products"
          item-key="name"
          show-select
          class="elevation-1 table-one"
          multi-sort
        >
          <template v-slot:item.productId="{item}">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                    color="success"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="prepareEditProduct(item.productId)"
                    icon
                  >
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.issold="{item}">
            <template v-if="item.issold">
              <v-chip
                class=""
                color="success"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  >mdi-check</v-icon>
              </v-chip>
            </template>
            <template v-else>
              <v-chip
                class=""
                color="danger"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  >mdi-close</v-icon>
              </v-chip>
            </template>
          </template>
          <template v-slot:item.ispurchased="{item}">
            <template v-if="item.ispurchased">
              <v-chip
                class=""
                color="success"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  >mdi-check</v-icon>
              </v-chip>
            </template>
            <template v-else>
              <v-chip
                class=""
                color="danger"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  >mdi-close</v-icon>
              </v-chip>
            </template>
          </template>
          <template v-slot:item.isstocked="{item}">
            <template v-if="item.isstocked">
              <v-chip
                class=""
                color="success"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  >mdi-check</v-icon>
              </v-chip>
            </template>
            <template v-else>
              <v-chip
                class=""
                color="danger"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  >mdi-close</v-icon>
              </v-chip>
            </template>
          </template>
        </v-data-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Table One',
    },
    data() {
      return {
        search: '',
        validProductForm: false,
        editProduct: false,
        dialog: false,
        productFormData: {
          productId: 0,
          organizationId: 0,
          productCategoryId: 1,
          value: "",
          name: "",
          description: "",
          isstocked: true,
          ispurchased: true,
          issold: true,
          isbom: true,
          taxRates: [],
          currencyId: 0,
          price: 0,
          active: true
        },
        selected: [],
        headers: [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Price', value: 'price' },
          { text: 'Description', value: 'description' },
          { text: 'Is Stocked', value: 'isstocked' },
          { text: 'Is Puchased', value: 'ispurchased' },
          { text: 'Is Sold', value: 'issold' },
          { text: 'Action', value: 'productId' },
        ],
      }
    },
    mounted () {
      this.fetchTaxRates()
      this.fetchCurrencies()
      this.fetchProducts()
    },
    computed: {
      ...mapGetters(['loggedInUser', 'taxRates', 'getCurrencyList', 'products'])
    },
    methods: {
      ...mapActions(['fetchTaxRates', 'fetchCurrencies', 'fetchProducts', 'createProduct', 'updateProduct']),
      productById (id) {
        let product = null
        this.products.forEach(element => {
          if (element.productId === id) {
            product = element
          }
        });
        return product
      },
      prepareEditProduct (id) {
        let product = this.productById(id)

        if (product) {
          // console.log(id)
          this.productFormData.productId = product.productId
          this.productFormData.organizationId = product.organizationId
          this.productFormData.productCategoryId = product.productCategoryId
          this.productFormData.value = product.value
          this.productFormData.name = product.name
          this.productFormData.description = product.description
          this.productFormData.isstocked = product.isstocked
          this.productFormData.ispurchased = product.ispurchased
          this.productFormData.issold = product.issold
          this.productFormData.isbom = product.isbom
          this.productFormData.currencyId = product.currencyId
          this.productFormData.price = product.price
          this.productFormData.taxRates = []

          product.taxRates.forEach(element => {
            this.productFormData.taxRates.push(element.id)
          });
          this.editProduct = true
          this.dialog  = true
        }
      },
      submitProduct () {
        this.productFormData.organizationId = this.loggedInUser.organizations[0].id
        
        if (this.editProduct) {
          this.updateProduct(this.productFormData)
        }
        else {
          this.createProduct(this.productFormData)
        }
        this.editProduct = false
        this.dialog = false
      }
    }
  }
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
